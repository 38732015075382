<template>
    <div>
        <app-layout>
	        <template v-slot:header>
                <Header :title="$t('batch_update_due_date')" :is-filter="false"></Header>
	        </template>
	        <template v-slot:header-mobile>
                <HeaderMobile :title="$t('batch_update_due_date')" :is-filter="false"></HeaderMobile>
	        </template>
            <ValidationObserver ref="formValidate">
                <b-row class="mt-5">
                    <b-col cols="6" xs="12">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox v-model="formData.payment_type_id"
                                                                 type="bank_credit"
                                                                   :validate="errors[0]">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="period_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('period')">
                                        <payment-periods-selectbox v-model="formData.period_id"
                                                           :validateError="errors[0]">
                                        </payment-periods-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="due_date_start" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('due_date_start')">
                                        <b-input type="date" v-model="formData.due_date_start">
                                        </b-input>
                                        <span class="invalid-feedback-custom"
                                              v-show="errors[0]"
                                              v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="due_date_end" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('due_date_end')">
                                        <b-input type="date" v-model="formData.due_date_end">
                                        </b-input>
                                        <span class="invalid-feedback-custom"
                                              v-show="errors[0]"
                                              v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="payment_date_start" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('payment_date_start')">
                                        <b-input type="date" v-model="formData.payment_date_start">
                                        </b-input>
                                        <span class="invalid-feedback-custom"
                                              v-show="errors[0]"
                                              v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="payment_date_end" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('payment_date_end')">
                                        <b-input type="date" v-model="formData.payment_date_end">
                                        </b-input>
                                        <span class="invalid-feedback-custom"
                                              v-show="errors[0]"
                                              v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12">
                                <ValidationProvider name="due_date" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('due_date')">
                                        <b-input type="date" v-model="formData.due_date">
                                        </b-input>
                                        <span class="invalid-feedback-custom"
                                              v-show="errors[0]"
                                              v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" xs="12">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="formData.number"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <div class="d-flex mt-2">
                    <b-button variant="primary" @click="update">{{ $t('update') }}</b-button>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

    import LinedTextarea from "@/components/elements/LinedTextarea";

    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
    import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox";
    import PaymentService from "@/services/PaymentService";
    import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";

    export default {
        components: {
            PaymentTypesSelectbox,
            PaymentProductsSelectbox,
            PaymentPeriodsSelectbox,
            AppLayout,
            Header,
            HeaderMobile,
            LinedTextarea,

            ValidationProvider,
            ValidationObserver,
        },
        metaInfo() {
            return {
                title: this.$t('batch_update_due_date')
            }
        },
        computed: {
            convertStudentNumbers() {
                if(this.formData.number){
                    return this.formData.number.split(/\n/);
                }
                return [];
            }
        },
        data() {
            return {
                formData: {}
            }
        },
        methods: {
            async update() {
                if(this.checkPermission('payment_updateduedate')){
                    const isValid = await this.$refs.formValidate.validate();
                    if (isValid) {
                        let formData = {
                            ...this.formData
                        }

                        formData.number = this.convertStudentNumbers;
                        PaymentService.updateDueDate(formData)
                                      .then(response=>{
                                          if(!response.data.success){
                                              this.toast(response.data);
                                              return;
                                          }

                                          this.$toast.success(this.$t('number_of_records_updated')+': '+response.data.data.count);
                                          if(response.data.data.numbers && response.data.data.numbers.length>0){
                                              this.$swal.fire(this.$t('student_numbers'), response.data.data.numbers.join("<br>"));
                                          }

                                      }).catch(e=>{this.showErrors(e)});
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },
        }
    };
</script>

